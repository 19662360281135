import request from '@/utils/request'

export function getEscalationList(params, signal) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityEscalationByPage`,
    params,
    signal
  })
}

export const exportQualityEscalation = `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/exportQualityEscalation`
