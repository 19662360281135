import { generateListByMap } from '@/utils'

export const escalationStatusMap = {
  1: "Awaiting TL's Judgement",
  2: "Awaiting QA Agent's Judgement",
  3: 'Accepted',
  4: 'Rejected'
}

export const { list: escalationStatusList, enum: escalationStatusEnum } = generateListByMap(escalationStatusMap)
