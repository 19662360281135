<template>
  <div>
    <el-form inline size="small">
      <el-form-item label="Ticket Number">
        <el-input
          v-model="form.workNumber"
          clearable
          placeholder="Input"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="Agent">
        <el-select
          v-model="form.agent"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in customerServiceList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Processing Status">
        <el-select
          v-model="form.escalationStatus"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in escalationStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Escalation Time">
        <el-date-picker
          v-model="form.escalationTime"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="~"
          :clearable="false"
          start-placeholder="Start"
          end-placeholder="End"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          Search
        </el-button>
        <el-button @click="handleReset">Reset</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button @click="handleExport">Export</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="workNumber" title="Ticket Number" min-width="120" />
      <vxe-column field="agent" title="Agent" min-width="80" />
      <vxe-column field="scoreNature" title="Score Nature" min-width="120">
        <template #default="{row}">
          {{ getScoreNature(row.scoreNature) }}
        </template>
      </vxe-column>
      <vxe-column field="reason" show-overflow title="Reason for Escalation" min-width="220">
        <template #default="{row}">
          {{ filterImg(row.reason) }}
        </template>
      </vxe-column>
      <vxe-column field="escalationStatus" title="Processing Status" min-width="120">
        <template #default="{row}">
          {{ getEscalationStatus(row.escalationStatus) }}
        </template>
      </vxe-column>
      <vxe-column field="escalationTime" title="Escalation Time" min-width="150" />
      <vxe-column field="tlAgent" title="TL Agent" min-width="100" />
      <vxe-column field="tlComment" title="TL Comment" show-overflow min-width="150" />
      <vxe-column field="qaAgent" title="QA Agent" min-width="100" />
      <vxe-column field="qaComment" title="QA Comment" show-overflow min-width="150" />
      <vxe-column title="Operation" min-width="120">
        <template #default="{row}">
          <el-button type="text" @click="handleJumpDetail(row)">
            Ticket detail
          </el-button>
          <el-button type="text" @click="handleOpenScoreDialog(row)">
            QA Detail
          </el-button>
          <!--只对QA/TL开放-->
          <el-button
            v-if="isQA || isTeamLeader"
            :disabled="determineDisabled(row)"
            type="text"
            @click="handleOpenJudgeDialog(row)"
          >
            Judge escalation
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <judge-escalation-dialog
      :judge-type="judgeType"
      :row.sync="currentRow"
      :visible.sync="escalateDialogVisible"
      @refresh="handleQuery"
    />
    <score-dialog
      :row.sync="currentRow"
      :visible.sync="scoreDialogVisible"
      type="detail"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import JudgeEscalationDialog from './JudgeEscalationDialog.vue'
import { escalationStatusEnum, escalationStatusList, escalationStatusMap } from './const'
import { getEscalationList, exportQualityEscalation } from './apis/list'
import { getCustomerServiceList } from '../work-order-quality-inspection/apis/list'
import ScoreDialog from '../work-order-quality-inspection/ScoreDialog.vue'
import { roleEnum, scoreNatureMap } from '../const'
import { getQualityAgentList } from '../customer-service/apis/list'
import { statusEnum as roleStatusEnum } from '../customer-service/const'
import qs from 'qs'

export default {
  name: 'EscalationManageList',
  components: {
    JudgeEscalationDialog,
    ScoreDialog,
    Paging
  },
  data() {
    return {
      qaList: [],
      teamLeaderList: [],
      customerServiceList: [],
      form: {
        workNumber: '',
        agent: [],
        escalationStatus: [],
        escalationTime: []
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      currentRow: null,
      scoreDialogVisible: false,
      judgeType: 'tl',
      escalateDialogVisible: false
    }
  },
  computed: {
    username() {
      return this.$store.state.user.name
    },
    isCustomerService() {
      return this.customerServiceList.find(e => e.toLowerCase() === this.username.toLowerCase())
    },
    isQA() {
      // 忽略大小写
      return this.qaList.find(e => e.agent.toLowerCase() === this.username.toLowerCase())
    },
    isTeamLeader() {
      // 忽略大小写
      return this.teamLeaderList.find(e => e.agent.toLowerCase() === this.username.toLowerCase())
    },
    teamLeaderGroup() {
      return this.teamLeaderList.filter(e => e.agent.toLowerCase() === this.username.toLowerCase()).map(e => e.group)
    },
    queryParams() {
      const [escalationTimeStart, escalationTimeEnd] = this.form.escalationTime
      return {
        ...this.form,
        ...this.pager,
        escalationTimeStart,
        escalationTimeEnd,
        agent: this.form.agent.join(','),
        escalationStatus: this.form.escalationStatus.join(','),
        workNumber: this.form.workNumber.trim()
      }
    },
    escalationStatusList() {
      return escalationStatusList
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.getCustomerServiceListList()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.getRoleList()
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleExport() {
      const [escalationTimeStart, escalationTimeEnd] = this.form.escalationTime
      const params = {
        ...this.form,
        escalationTimeStart,
        escalationTimeEnd
      }
      delete params.escalationTime
      const url = exportQualityEscalation + '?' + qs.stringify(params, { arrayFormat: 'comma' })
      window.open(url, '_blank')
    },
    /*
     if 处理状态==“Awaiting TL's Judgement”，仅TL可点击【Judge escalation】按钮，对QA置灰；
     else if 处理状态==“Awaiting QA Agent's Judgement”，仅QA可点击【Judge escalation】按钮，对TL置灰；
     else，置灰，不可点击。
     仅在处理过该工单的质检的QA可以处理该工单的申诉。
     仅本小组的TL可处理本小组的客服的申诉。
     */
    determineDisabled(row) {
      if (row.escalationStatus === escalationStatusEnum['Awaiting TL\'s Judgement']) {
        return !(this.isTeamLeader && this.teamLeaderGroup.includes(row.group))
      }
      if (row.escalationStatus === escalationStatusEnum['Awaiting QA Agent\'s Judgement']) {
        return !(this.isQA && row.qaAgent === this.username)
      }
      return true
    },
    async getRoleList() {
      const { datas } = await getQualityAgentList({
        delFlag: roleStatusEnum['启用'],
        current: 1,
        size: 1000
      })
      const { records } = datas || {}
      if (records?.length) {
        this.teamLeaderList = records.filter(item => item.role === roleEnum['TeamLeader'])
        this.qaList = records.filter(item => item.role === roleEnum['质检员'])
      }
      if (this.isQA) {
        this.form.escalationStatus = [escalationStatusEnum['Awaiting QA Agent\'s Judgement']]
      }
      if (this.isTeamLeader) {
        this.form.escalationStatus = [escalationStatusEnum['Awaiting TL\'s Judgement']]
      }
      if (this.isQA && this.isTeamLeader) {
        this.form.escalationStatus = [
          escalationStatusEnum['Awaiting QA Agent\'s Judgement'],
          escalationStatusEnum['Awaiting TL\'s Judgement']
        ]
      }
      if (this.isCustomerService && this.isQA && this.isTeamLeader) {
        this.form.escalationStatus = []
      }
      this.defaultForm = {
        ...this.form
      }
      await this.handleQuery()
    },
    filterImg(val) {
      const arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"',
        'ldquo': '“',
        'mdash': '—',
        'rdquo': '”',
        'ndash': '—'
      }
      // 过滤 <img src=\"https:// 为 [图片]
      // 过滤 html 标签
      // 过滤 特殊符号
      return val
        .replace(/<img src="http(s)?:\/\/.*?".*?>/g, '[图片]')
        .replace(/<[^>]+>/g, '')
        .replace(/&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo|ndash);/ig, (all, t) => arrEntities[t])
    },
    getEscalationStatus(val) {
      return escalationStatusMap[val]
    },
    getScoreNature(val) {
      return scoreNatureMap[val]
    },
    handleJumpDetail(row) {
      const router = this.$router.resolve({
        path: '/service-manage/Buyer-mail-message/message-reply',
        query: {
          id: row.messageId,
          disabled: 'true'
        }
      })
      window.open(router.href, '_blank')
    },
    handleOpenScoreDialog(row) {
      this.currentRow = {
        id: row.qualityId
      }
      this.scoreDialogVisible = true
    },
    async getCustomerServiceListList() {
      const { datas } = await getCustomerServiceList()
      this.customerServiceList = datas || []
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getEscalationList(this.queryParams, signal).finally(() => {
        this.queryLoading = this.queryChange = false
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleReset() {
      this.form = {
        ...this.defaultForm
      }
      this.handleQuery()
    },
    handleOpenJudgeDialog(row) {
      this.currentRow = row
      if (row.escalationStatus === escalationStatusEnum['Awaiting QA Agent\'s Judgement']) {
        this.judgeType = 'qa'
      } else {
        this.judgeType = 'tl'
      }
      this.escalateDialogVisible = true
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
